<template>
    <router-view />
</template>

<script lange="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'IndexSubscriptionPage',
});
</script>
